define("discourse/plugins/onlyoffice-discourse-footer/discourse/initializers/onlyoffice-whos-online", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "onlyoffice-whos-online",
    initialize(container) {}
  };
});