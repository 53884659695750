define("discourse/plugins/onlyoffice-discourse-footer/discourse/services/user-service", ["exports", "@ember/service", "discourse/lib/ajax"], function (_exports, _service, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _service.default.extend({
    users: {
      online: 0,
      hidden: 0,
      anonymous: 0
    },
    init() {
      this._super(...arguments);
      (0, _ajax.ajax)("/whosonline/get.json", {
        method: "GET"
      }).then(result => {
        if (result.users) {
          this.set("users", result.users);
        }
      }).catch(e => {
        console.log(e);
      });
    }
  });
});